
import { computed, defineComponent, reactive } from 'vue';
import clone from 'lodash.clone';
import LocationTypeService from '@/modules/master-data/services/LocationTypeService';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import LocationType, { StaticLocationType } from '@/domain/LocationType';
import { TableDefinition } from '@/types';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { CycleCountFrequency, ToOptionsList } from '@/domain/cyclecount/CycleCountFrequency';
import CycleCountPreferences from '@/domain/cyclecount/CycleCountPreference';
import BTab from '@/components/bootstrap-library/BTab.vue';
import BTabs from '@/components/bootstrap-library/BTabs.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import router from '@/router';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import Screen from '@/components/layout/Screen.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    locationType: LocationType;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'location-type-list',
    components: {
        BDropdown,
        BDropdownItem,
        BAdvancedTable,
        BButton,
        BFormCheckbox,
        BFormSelect,
        TextInput,
        BModal,
        Screen,
        BSpinner,
        BTab,
        BTabs,
        BFormInput,
    },
    setup() {
        const locationTypeService = new LocationTypeService();
        const { configStore } = masterDataStore.getInstance();

        const { validationResult: locationTypeValidationResult, validateForm: locationTypeValidateForm, clearResults: locationTypeClearResults } = useValidator<LocationType>('location-type');

        const { validationResult: cycleCountPreferenceValidationResult, validateForm: cycleCountPreferenceValidateForm, clearResults: cycleCountPreferenceClearResults } = useValidator<
            CycleCountPreferences
        >('cycle-count-preferences');

        const validateForm = (locationType: LocationType) => {
            locationTypeValidateForm(locationType);
            cycleCountPreferenceValidateForm(locationType.cycleCountPreferences as CycleCountPreferences);
        };

        const clearResults = () => {
            locationTypeClearResults();
            cycleCountPreferenceClearResults();
        };

        const state = reactive<State>({
            showForm: false,
            locationType: new LocationType(),
            modalTitle: '',
            saving: false,
        });

        const table = computed(
            (): TableDefinition<LocationType> => ({
                items: configStore.locationTypes.filter((type) => type.staticTypeIdentifier !== StaticLocationType.BuiltIn),
                key: 'locationTypeList',
                name: getTitleCaseTranslation('core.domain.locationTypes'),
                columnDefinition: [
                    {
                        key: 'description',
                        label: getTitleCaseTranslation('core.domain.description'),
                        searchable: true,
                    },
                ],
            }),
        );

        const frequencies = ToOptionsList(CycleCountFrequency);

        const countAllContainers = computed(
            () => state.locationType.cycleCountPreferences?.containersCount === undefined || state.locationType.cycleCountPreferences?.containersCount === null,
        );
        const cycleCountPrefsDisabled = computed(() => !state.locationType.cycleCountPreferences);
        const emailNotificationDaysCheckboxDisabled = computed(() => cycleCountPrefsDisabled.value || state.locationType.cycleCountPreferences?.frequency === CycleCountFrequency.Daily);
        const containerCountDisabled = computed(() => countAllContainers.value || cycleCountPrefsDisabled.value);
        const containerCountValidationResult = computed(
            () => countAllContainers.value || state.locationType.cycleCountPreferences?.containersCount || cycleCountPrefsDisabled.value,
        );

        function openAdd() {
            clearResults();
            state.locationType = new LocationType();
            state.locationType.cycleCountPreferences = new CycleCountPreferences();
            state.modalTitle = getTitleCaseTranslation('core.domain.newLocationType');
            state.showForm = true;
        }

        function openEdit(item: LocationType) {
            clearResults();
            // have to clone locationType/cycleCountPreferences objects.
            // otherwise changes are saved (by ref) to the objects in the configStore even if the user doesn't `save`
            state.locationType = new LocationType(clone(item));
            if (!state.locationType.cycleCountPreferences) {
                state.locationType.cycleCountPreferences = new CycleCountPreferences();
            } else {
                state.locationType.cycleCountPreferences = new CycleCountPreferences(clone(state.locationType.cycleCountPreferences));
            }
            state.modalTitle = getTitleCaseTranslation('core.domain.editLocationType');
            state.showForm = true;
        }

        async function openLocationTypesHistory(item: LocationType) {
            await router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                // todo change entityType to LocationType entityType once it's supported
                params: { entityType: EntityType.LOCATION_TYPE, entityId: item.id },
            });
        }

        async function save() {
            validateForm(state.locationType);

            if (locationTypeValidationResult.isValid && cycleCountPreferenceValidationResult.isValid && containerCountValidationResult.value) {
                state.saving = true;

                if (state.locationType.id > 0) {
                    const response = await locationTypeService.updateLocationType(state.locationType);
                    if (response) {
                        state.showForm = false;
                    }
                } else {
                    const response = await locationTypeService.addNewLocationType(state.locationType);
                    if (response) {
                        state.showForm = false;
                    }
                }

                state.saving = false;
            }
        }

        function setCountItemsUnderLoad(countItemsUnderLoadFlag: boolean) {
            if (state.locationType.cycleCountPreferences) {
                state.locationType.cycleCountPreferences.countItemsUnderLoad = countItemsUnderLoadFlag;

                if (state.locationType.cycleCountPreferences.containersCount !== undefined && countItemsUnderLoadFlag) {
                    state.locationType.cycleCountPreferences.containersCount = undefined;
                }
            }
        }

        return {
            state,
            table,
            frequencies,
            cycleCountPrefsDisabled,
            openAdd,
            openEdit,
            openLocationTypesHistory,
            save,
            emailNotificationDaysCheckboxDisabled,
            containerCountDisabled,
            locationTypeValidationResult,
            cycleCountPreferenceValidationResult,
            containerCountValidationResult,
            countAllContainers,
            getTitleCaseTranslation,
            setCountItemsUnderLoad,
        };
    },
});
