import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d7bed4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox" }
const _hoisted_2 = { class: "b-form-input" }
const _hoisted_3 = { class: "text-input" }
const _hoisted_4 = { class: "checkbox-grouping" }
const _hoisted_5 = { class: "checkbox-label" }
const _hoisted_6 = ["hidden"]
const _hoisted_7 = { class: "b-form-input checkbox-grouping" }
const _hoisted_8 = { class: "checkbox" }
const _hoisted_9 = { class: "checkbox-label" }
const _hoisted_10 = { class: "b-form-input checkbox-grouping" }
const _hoisted_11 = { class: "checkbox" }
const _hoisted_12 = { class: "b-form-input checkbox-grouping" }
const _hoisted_13 = { class: "checkbox" }
const _hoisted_14 = { class: "b-form-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showForm,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.showForm) = $event)),
        centered: "",
        title: _ctx.state.modalTitle
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.saving,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_tabs, { style: {"min-height":"220px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_b_tab, {
                title: _ctx.getTitleCaseTranslation('core.domain.locationTypeDetails')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_text_input, {
                    modelValue: _ctx.state.locationType.description,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.locationType.description) = $event)),
                    error: _ctx.locationTypeValidationResult.model.description,
                    label: _ctx.getTitleCaseTranslation('core.domain.description'),
                    autofocus: ""
                  }, null, 8, ["modelValue", "error", "label"]),
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_b_form_checkbox, {
                      modelValue: _ctx.state.locationType.includeInPushPlanning,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.locationType.includeInPushPlanning) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.includeInPushPlanning')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_b_tab, {
                title: _ctx.getTitleCaseTranslation('core.domain.cycleCountPreferences')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.frequency')), 1),
                    _createVNode(_component_b_form_select, {
                      "model-value": _ctx.state.locationType.cycleCountPreferences?.frequency,
                      options: _ctx.frequencies,
                      label: _ctx.getTitleCaseTranslation('core.domain.frequency'),
                      "text-field": "name",
                      "value-field": "id",
                      onChange: _cache[2] || (_cache[2] = (val) => _ctx.state.locationType.cycleCountPreferences?.changeFrequency(val))
                    }, null, 8, ["model-value", "options", "label"])
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.cycleCountCreationDaysBeforeDue')), 1),
                    _createVNode(_component_text_input, {
                      "model-value": _ctx.state.locationType.cycleCountPreferences?.creationDaysBeforeDue,
                      class: "creation-date",
                      disabled: "",
                      error: _ctx.cycleCountPreferenceValidationResult.model.containersCount
                    }, null, 8, ["model-value", "error"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.containersToCount')), 1),
                    _createElementVNode("label", {
                      hidden: !!_ctx.containerCountValidationResult,
                      class: "checkbox-label error"
                    }, _toDisplayString(_ctx.getTitleCaseTranslation('core.validation.pleaseProvideAValue')), 9, _hoisted_6)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_b_form_checkbox, {
                        "model-value": _ctx.countAllContainers,
                        disabled: _ctx.cycleCountPrefsDisabled,
                        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.state.locationType.cycleCountPreferences?.toggleCountAllContainers()))
                      }, null, 8, ["model-value", "disabled"]),
                      _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.button.all')), 1)
                    ]),
                    _createVNode(_component_text_input, {
                      "model-value": _ctx.state.locationType.cycleCountPreferences?.containersCount,
                      class: "checkbox-field",
                      disabled: _ctx.containerCountDisabled,
                      error: _ctx.cycleCountPreferenceValidationResult.model.containersCount,
                      placeholder: "1-50",
                      onChange: _cache[4] || (_cache[4] = (val) => (_ctx.state.locationType.cycleCountPreferences.containersCount = val))
                    }, null, 8, ["model-value", "disabled", "error"])
                  ]),
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.emailReminderNotificationDaysBeforeDue')), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_b_form_checkbox, {
                        "model-value": _ctx.state.locationType.cycleCountPreferences?.areNotificationsEnabled,
                        disabled: _ctx.emailNotificationDaysCheckboxDisabled,
                        onChange: _cache[5] || (_cache[5] = (val) => (_ctx.state.locationType.cycleCountPreferences.areNotificationsEnabled = val))
                      }, null, 8, ["model-value", "disabled"]),
                      _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.enabled')), 1)
                    ]),
                    _createVNode(_component_text_input, {
                      "model-value": _ctx.state.locationType.cycleCountPreferences?.notificationDaysBeforeDue,
                      class: "checkbox-field",
                      disabled: ""
                    }, null, 8, ["model-value"])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_b_form_checkbox, {
                        "model-value": _ctx.state.locationType.cycleCountPreferences?.countItemsUnderLoad,
                        onChange: _ctx.setCountItemsUnderLoad
                      }, null, 8, ["model-value", "onChange"]),
                      _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.countItemsUnderLoad')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.inventoryAdjustmentThreshold')), 1),
                    _createVNode(_component_b_form_input, {
                      "model-value": _ctx.state.locationType.cycleCountPreferences?.inventoryAdjustmentThresholdPercent,
                      type: "number",
                      min: 0,
                      max: 100,
                      "hide-stepper": "",
                      error: _ctx.cycleCountPreferenceValidationResult.model.inventoryAdjustmentThreshold,
                      placeholder: "1-100",
                      "append-group-text": "%",
                      onChange: _cache[6] || (_cache[6] = (val) => (_ctx.state.locationType.cycleCountPreferences.inventoryAdjustmentThresholdPercent = val))
                    }, null, 8, ["model-value", "error"])
                  ])
                ]),
                _: 1
              }, 8, ["title"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        "dynamic-columns": ""
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            onClick: _ctx.openAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addLocationType')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openEdit(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openLocationTypesHistory(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["table-array"])
    ]),
    _: 1
  }))
}